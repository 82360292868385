import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import React from 'react';
// import Icon from './icon';
import TechList from './tech-list';
import { StyledContentLink } from './_shared/styled-content-link';
import { StyledH2 } from './_shared/styled-headings';
import { flexEnd } from './_shared/styled-mixins';
import { StyledTextSection } from './_shared/styled-text-section';
import { StyledStaticImageContainer } from './_shared/styled-image-container';


const StyledProject = styled.div`
  position: relative;
  min-height: 16rem;

  &:hover a {
    color: var(--title-color);
  }

  &:before {
    content: '';
    position: absolute;
    bottom: -10px;
    right: -10px;
    width: 98%;
    height: 98%;
    border: 2px solid var(--primary-color);
    transition: all ease var(--transition-fast);
  }
`;

const StyledHeader = styled.header`
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
`;

const Logo = styled.img` 
  width: 100%;
  max-height: 3rem;
  object-fit: contain;
`

const StyledInfoContainer = styled.section`
  box-sizing: border-box;
  padding: 2rem 3rem;
  max-height: 20rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  background: rgba(0,0,0,0.8);
  backdrop-filter: blur(5px);
  max-width: 100%;
  height: 100%;
  opacity: 0;
  transition: 150ms ease-in;

  & > * {
    pointer-events: none;
  }

  &:hover {
    opacity: 1;

    & > * {
      pointer-events: initial;
    }
  }
`;

const StyledProjectText = styled.div`
  > p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
`;

const StyledProjectImage = styled.div`
  margin-right: 2rem;
  width:100%;
  height: 100%;

  & * {
    height: 100%;
  }

  & img {
    object-fit: cover;
  }
`

const ProjectList = ({ projects }) => {
  return projects.map((project) => {
    const title = project.frontmatter.title;
    const coverImage = project.frontmatter.cover_image ? project.frontmatter.cover_image.childImageSharp.fluid : null;
    const logoImage = project.frontmatter.logo ? project.frontmatter.logo : null;
    const demoLink = project.frontmatter.demo_link;
    return (
      <StyledProject key={title}>

        <StyledHeader>
          {coverImage && (
              
              <StyledProjectImage>
                <Img fluid={coverImage} />
              </StyledProjectImage>
            ) || <span>Image Missing</span>}


        </StyledHeader>
          <a href={demoLink ? demoLink : '#'} target="_blank" rel="noreferrer">

            <StyledInfoContainer>

              {logoImage && (
                <Logo src={logoImage.publicURL} />
              ) || <StyledH2>{title}</StyledH2>}

              <StyledProjectText dangerouslySetInnerHTML={{ __html: project.html }} />

              <TechList techs={project.frontmatter.techs} />

            </StyledInfoContainer>

          </a>

      </StyledProject>
    );
  });
};

ProjectList.propTypes = {
  projects: PropTypes.array.isRequired,
};

export default ProjectList;
