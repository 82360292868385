import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import ProjectList from '../components/project-list';
import SEO from '../components/seo';
import { mq } from '../components/_shared/media';
import { blogMenuLinks } from '../components/_config/menu-links';
import { StyledH1 } from '../components/_shared/styled-headings';
import { StyledFullHeightSection } from '../components/_shared/styled-section';

const StyledProjectsH1 = styled(StyledH1)`
  margin-top: 3rem;
`;

const ProjectListContainer = styled.div`
  display: grid;
  gap: 5rem;

  ${mq.gt.sm} {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem
  }
`
const Projects = ({
  data: {
    allMarkdownRemark: { nodes },
  },
}) => {
  // console.log(nodes);
  return (
    <Layout menuLinks={blogMenuLinks}>
      <SEO title="Projects" />
      <StyledFullHeightSection>
        <StyledProjectsH1>Projects</StyledProjectsH1>
        <ProjectListContainer>
          <ProjectList projects={nodes} />

        </ProjectListContainer>
      </StyledFullHeightSection>
    </Layout>
  );
};

export default Projects;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      filter: { fileAbsolutePath: { regex: "/content/projects/" }, frontmatter: { enabled: { eq: true } } }
    ) {
      nodes {
        frontmatter {
          date(formatString: "D MMMM, YYYY")
          title
          demo_link
          techs
          logo {
            childImageSharp {
              fluid(maxWidth: 500, quality: 92) {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
          cover_image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        html
      }
    }
  }
`;
